<template>
  <div>
    <datatable :data="transactions" :columns="columns" />
  </div>
</template>
<script>
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          name: "description",
          th: "Description",
        },
        {
          name: "category",
          th: "Category",
          render: (transaction, category) => `${category || ""}`,
        },
        /*{
            name: 'type',
            th: 'Transaction Type',
          },*/
        {
          name: "amount",
          th: "Amount",
          render: (transaction, amount) =>
            `₦ ${this.$options.filters.currency(amount)}`,
        },
        {
          name: "status",
          th: "Status",
          render: (transaction, status) => this.transactionStatusBadge(status),
        },
        {
          name: "created_at",
          th: "Date",
          render: (transaction, created_at) =>
            this.$options.filters.dateFormat(created_at, "D, dd M Y h:ia"),
        },
      ],
    };
  },
  computed: {
    transactions() {
      return this.staff?.personal_account?.personal_transactions || [];
    },
  },
};
</script>